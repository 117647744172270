import React, { useEffect } from "react"
import "../../../assets/styles/_index.scss"
import Favicon from "react-favicon"
import ShobaDreamGardens from "../../../components/developers/shobadeveloper/ourproperties/ShobaDreamGardens";
import TagManager from 'react-gtm-module'

const favicon_logo = require("../../../images/favicon.png");

const ShobaDreamGardensPage = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-J9C48LD' });
  }, []);

  return (
    <>
      <Favicon url={favicon_logo} />

      <ShobaDreamGardens />
    </>
  )
}

export default ShobaDreamGardensPage
